import { AuthModel } from './_models';
import { useAuth } from './Auth';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const getAuth = (): AuthModel | undefined => {
	if (!localStorage) {
		return;
	}
	const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
	if (!lsValue) {
		return;
	}
	try {
		const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
		if (auth) {
			// You can easily check auth_token expiration also
			return auth;
		}
	} catch (error) {
		console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
	}
}

const setAuth = (auth: AuthModel) => {
	if (!localStorage) {
		return;
	}
	try {
		const lsValue = JSON.stringify(auth);
		localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
	}
}

const removeAuth = () => {
	if (!localStorage) {
		return;
	}
	try {
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
	}
}

export function setupAxios(axios: any) {
	axios.defaults.headers.Accept = 'application/json';
	axios.interceptors.request.use((config: { headers: { Authorization: string } }) => {
		const auth = getAuth();
		if (auth && auth.token) {
			config.headers.Authorization = `${auth.token}`;
		}
		return config;
	}, (err: any) => Promise.reject(err));
	axios.interceptors.response.use((response) => response, async (error) => {
		if (error.response.status === 406) {
			removeAuth()
			document.location.reload();
		} else {
			const originalRequest = error.config;
			// If the error status is 401 and there is no originalRequest._retry flag,
			// it means the token has expired and we need to refresh it
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				try {
					const auth = getAuth();
					if (auth && auth.token) {
						const API_URL = process.env.REACT_APP_API_URL;
						const GET_USER_BY_REFRESHTOKEN_URL = `${API_URL}/auth/refresh-token`;
						const refreshToken = auth.refresh_token;
						const result = await axios.post(GET_USER_BY_REFRESHTOKEN_URL, { refreshToken });
						setAuth(result.data);
						originalRequest.headers.Authorization = `${result.data.token}`;
						return axios(originalRequest);
					}
				} catch (error) {
					// Handle refresh token error or redirect to login
				}
			}
		}
		return Promise.reject(error);
	});
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }